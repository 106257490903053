declare var HTTP_BASE_URL: string; // base url of collab server http api, defined by build tool(webpack)
// declare var WS_BASE_URL: string; // base url of collab server websocket api, defined by build tool(webpack)
declare var APP: string;
declare var VERSION: string;
declare var __webpack_public_path__: string;

console.table({
  'collaboration demo': {
    version: VERSION || 'public',
  },
});

function getConfig() {
  if (APP === 'dev') {
    return {
      serverUrl: HTTP_BASE_URL || `http://${window.location.hostname}:8080`,
    };
  } else {
    // 部署到 azk8s 环境
    return {
      // serverUrl: `https://${window.location.host}/collab-server`,
      serverUrl: HTTP_BASE_URL || `https://${window.location.hostname}`,
    };
  }
}

const config = getConfig();
export const serverUrl = config.serverUrl;

export const PUBLIC_PATH = __webpack_public_path__;

//@ts-ignore
// export const licenseSN = window.licenseSN;
export const licenseSN = 'ljgAp+VS8EHVDFZFYM5EjM3KB+JxUMDs6941roFTbcZ1HsFi1tZwWw==';
//@ts-ignore
// export const licenseKey = window.licenseKey;
export const licenseKey = 'PjwcmDRQMS6sbxteo9dUcD5R3hPDB2zs4zVNSoZn0xHsvUIR+5+O2qCa705RS3cdtwz9ZZocYrAkBxPPzRXKxM7zxlJIzx4b5PtovWbp2PJ/5VfoD26OEXIJcbCFN7y3z6qQompnLRGkZHcvj0WdP0pGaolhaF6QkeP4wRaA94eR/GRmquCDeH7Svv3Ppwmy0W7+tAd/8eXMSvMAm13PNJfmja8+lmS6x1bTXHeuoBk8NKxXd5DaIkvBJC8Y4gUdGrkBTq7w4jwRYdPcsz3Z/rFucrKAVNFFHsgRQA1uzGYXw4+z2atmXW/JjFJ76zd6MfHgwVr8hiSLUud+D8GQlIovTo7cZoJG85gconBZME2fUAXK/KcUuAguM/txe1tfXQVtFnuWbntLjY5COfW0xvpSiaGZzwLiNJ5A1kpGAFxViDB8VFaB+tJUQldnG+He5eA1mPtyDYJO3LMyF3msKrkV87/cV8WfG6R/uqTD26lpme8/tLAILis468ZTKjQh1NE+UsMwXKk2mKYivgiAVi24l1hipR7R4iw6w/So5TupNsscAY/tPI/5rJ429IzQCA40oEMy7IDDa0KgmFxPKGqOnv/5NgN9qCkBdcLQX+Y2d9B/6pTwjIcA4NcnbUQem9m67HZn65yHTdzl/inG1+gkXgvf2y9NPa20zAMjWeAbPMS7N+ag8UzWieh0kRT4NHVaC2i40XQNYWO7//U+gEY0C1ppSKuO0rL5fCqU6UWRH/GLlhrWGmModvTmKluRrNEat5G2gz2OnDDmXEIk5hu/ReqdznTJSJr2UnYHL4N4sRckD/FYihprxrpc0ymcFscss8kzyND+0jadebG8M9nTJZyoBM2Rkp9W2m2mkNbHPyXYN4K2b4xI4RD/6RehJZvcp57hok0RSqwIjGGb7Sh60lbgZgw+R7Z9lE8aNpU5jsBu/z2BrJSFE+71ZPClaJlu6wuLX9YtLVlEQe2yRE5SOiUfw1EtkGYH6jJlryhyLLyg6kpWq7ciC/h3dA7PewrXWs4160kDHWgqZFeAQPxFeo9pz4k+LN0uMSqHbsRUpaGMo/pIz6f+9Ido3n5SNO+Eobp6jOHnfm61qDY5PFnXjSWebVHdj3a6fPvHkG76woz0Bb7bHH1hJNT3KcW3E6dsGVAb4CknY1QuoHJutYDP/kmGbGV/jQnp8GMfI6ICoo0jU2ao/AUXpP3ZfLcQF8ata3rlxtqAhN+XUNvpi9ZbkNePL3bqluJD9vvNq1/+1b2duSuhmKdePc3YyM55jw==';
